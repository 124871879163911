<template>
    <div>
        <b-tabs content-class="pt-1" v-model="transactionTabIndex">
            <b-tab @click="changeTransactionTabs('transaction')">
                <template #title>
                    <span>{{ $t('transaction') }}</span>
                </template>
            </b-tab>
            <b-tab @click="changeTransactionTabs('exchange')">
                <template #title>
                    <span>{{ $t('exchange') }}</span>
                </template>
            </b-tab>
        </b-tabs>
        <template v-if="transactionType === 'transaction'">
            <Table
                v-if="table"
                :url="url"
                :fields="fields"
                :body="body"
                :updateData="makeCall"
                :showSearch="false"
                :page="'transactions'"
                :title="$t('transactions')"
                :actionButton="
        data && data.role != null && data.role == 'debtor' ? button : false
      "
                @actionButtonTab="actionButtonTab"
            >
                <template #cell(id)="data">
                    <b-link @click="showTransaction(data.item)">
                        {{ data.item.id }}
                    </b-link>
                </template>
                <template #cell(amount)="data">
                    <span v-if="data.item.amount">{{ amountFormat(data.item.amount, data.item.currency.name) }}</span>
                    <span v-else>-</span>
                </template>
                <template #cell(currency)="data">
                    <span>{{ data.item.currency.name }}</span>
                </template>
                <template #cell(status)="data">
                    <span v-if="data.value == 0">{{ $t("pending") }} </span>
                    <span v-if="data.value == 1">{{ $t("approved") }}</span>
                    <span v-if="data.value == 2">{{ $t("declined") }}</span>
                    <span class="text-secondary" v-if="data.value == 3">{{
          $t("proof_of_ownership_is_in_progress")
        }}</span>
                </template>
            </Table>
            <Deposit
                v-else
                @showTable="showTable"
                :deposit="deposit"
                @storeDeposit="storeDeposit"
            ></Deposit>
        </template>

        <template v-else>
            <CurrencyTransaction/>
        </template>

    </div>
</template>
<script>
  import Table from "../../Common/Table2.vue";
  import Deposit from "./Depoist.vue";
  import CurrencyTransaction from "../../../views/currency/CurrencyTransaction";
  import { CurrencyAmountFormat } from '@core/utils/CurrencyAmountFormat';

  export default {
    components: {Table, Deposit, CurrencyTransaction},
    data() {
      return {
        transactionTabIndex: 0,
        transactionType: 'transaction',
        url: "/transactions/admin",
        button: {
          text: "deposit",
          link: "",
          color: "primary",
        },
        fields: [
          {
            key: "id",
            label: "id",
          },
          {
            key: "type_description",
            label: "Description",
          },
          {
            key: "currency",
            label: "currency",
          },
          {
            key: "amount",
            label: "amount",
          },
          {
            key: "status",
            label: "status",
          },
          {
            key: "reference_number",
            label: "reference_number_payment_reason",
          },
        ],
        table: true,
        deposit: null,
        transactions: [],
        body: {
          company_id: this.$route.params.id,
        },
      };
    },

    created() {
    },
    props: ['companyData', 'data'],
    methods: {
      amountFormat(amount, currency) {
        return CurrencyAmountFormat(amount, currency)
      },
      changeTransactionTabs(type) {
        this.transactionType = type
      },
      storeDeposit() {
        this.getTransactions();
      },
      showTransaction(item) {
        this.deposit = item;
        this.table = false;
      },
      actionButtonTab() {
        this.deposit = null;
        this.table = false;
      },
      makeCall() {
        this.getTransactions();
      },
      showTable() {
        this.table = true;
      },
      getTransactions() {
        this.$http
            .post("/transactions/admin", {comapny_id: this.$route.params.id})
            .then((res) => {
              if (res) {
                this.transactions = res.data.value.data;
                this.table = true;
              }
            });
      },
    },
  };
</script>
